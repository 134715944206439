import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  StyleRules,
  Tab,
  Tabs,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StopIcon from '@material-ui/icons/Stop';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { consultantGetAllProject } from '../../actions/projectProduceAction';
import {
  CancelCompanyPublishProjectDialog,
  ConsultantBreakProjectDialog,
} from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import { AppState } from '../../reducers';
import CheckBillsView from './checkBillsView';
import PublishProjectView from './publishProjectView';

const styles = (): StyleRules =>
  createStyles({
    contentPadding: {
      minHeight: '80vh',
      padding: '20px',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      // minHeight: '80vh',
      paddingBottom: '48px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    DialogWidth: {
      minWidth: '850px',
      border: '20px solid red',
    },
    list: {
      width: '100%',
      height: '100%',
      background: '#eee',
    },
    listContainer: {
      padding: '1.25rem',
      height: 'calc(100vh - 300px)',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: 0,
      },
    },
    listItem: {
      padding: '1.25rem',
      borderRadius: '1rem',
      background: '#fff',
      marginBottom: '1.25rem',
      position: 'relative',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    itemTop: {
      display: 'flex',
      alignItems: 'center',
    },
    projectName: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#333',
      marginRight: '1rem',
    },
    statusTag: {
      width: '3.5rem',
      height: '1.25rem',
      textAlign: 'center',
      lineHeight: '1.25rem',
      fontSize: '0.75rem',
      color: '#ff4d4f',
      border: '1px solid #ff4d4f',
      marginRight: '2rem',
      borderRadius: '0.6rem',
    },
    salary: {
      color: '#242f65',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
    spread: {
      background: '#f4f4f5',
      borderBottomLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      padding: '0.35rem',
      textAlign: 'center',
      fontSize: '0.75rem',
      position: 'absolute',
      top: 0,
      right: 0,
      color: '#7c8087',
      display: 'flex',
      alignItems: 'center',
    },

    itemMiddle: {
      margin: '1.25rem 0',
      display: 'flex',
      alignItems: 'center',
    },
    middleDescTag: {
      background: '#f4f4f5',
      textAlign: 'center',
      lineHeight: '1.5rem',
      color: '#4c4f54',
      fontSize: '0.75rem',
      padding: '0.2rem 0.5rem',
      marginRight: '0.5rem',
    },
    itemBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    bottomLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: '2rem',
      height: '2rem',
      marginBottom: 0,
    },
    companyName: {
      color: '#333',
      fontWeight: 'bold',
      margin: '0 0.75rem 0 0.5rem',
      display: 'flex',
      alignItems: 'center',
      // fontSize: '1.5rem',
    },
    text: {
      fontSize: '0.75rem',
      color: '#7c8087',
      marginLeft: '0.5rem',
    },
    delivery: {
      fontSize: '1rem',
      color: '#242f65',
      fontWeight: 'bold',
      '&:hover': {
        textShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    projectStartTime: {
      color: '#7c8087',
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',
    },
  });

enum ConsultantProjectStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  NONE = 'NONE',
}

type ConsultantProjectStatusType =
  | ConsultantProjectStatusEnum.COMPLETED
  | ConsultantProjectStatusEnum.INITIATED
  | ConsultantProjectStatusEnum.IN_PROGRESS
  | ConsultantProjectStatusEnum.NONE;

const projectListView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const projectProduce = useSelector((appState: AppState) => appState.projectProduce);

  const [value, setValue] = useState(ConsultantProjectStatusEnum.IN_PROGRESS);

  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  editOpen;
  const [breakDialog, setBreakDialog] = useState(false);
  const [checkBillsDialog, setCheckBillsDialog] = useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [dataList, setDataList] = useState<ProjectInfo[]>([]);
  const handleClose = () => {
    setOpen(false);
  };

  const checkBillsHandleClose = () => {
    setCheckBillsDialog(false);
  };

  const checkBillsHandleOpen = () => {
    setCheckBillsDialog(true);
  };
  const breakDialogOpen = () => {
    setBreakDialog(true);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ConsultantProjectStatusType) => {
    setValue(newValue);
    const filterData = projectProduce.projectProduceInfo.filter(item => item.status === newValue);
    setDataList(filterData);
  };

  const fetchData = async () => {
    await dispatch(consultantGetAllProject());
  };

  useEffect(() => {
    if (projectProduce.projectProduceInfo.length) {
      const filterData = projectProduce.projectProduceInfo.filter(
        item => item.status === 'IN_PROGRESS'
      );
      setDataList(filterData);
    }
  }, [projectProduce.projectProduceInfo]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet title="进行中的项目"></Helmet>
      <Grid container className={classes.contentPadding} justifyContent="space-between">
        <Paper square className={classes.childrenItem}>
          <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="进行中" value={ConsultantProjectStatusEnum.IN_PROGRESS} />
            <Tab label="已完成" value={ConsultantProjectStatusEnum.COMPLETED} />
            <Tab label="已结算" value={ConsultantProjectStatusEnum.INITIATED} />
          </Tabs>
          <div className={classes.list}>
            <div className={classes.listContainer}>
              {dataList.map(item => {
                return (
                  <div className={classes.listItem} key={item.id}>
                    <div className={classes.itemTop}>
                      <div className={classes.projectName}>
                        <AssignmentIcon />
                        {item.offer?.offerName}
                      </div>

                      <div className={classes.salary}>
                        <AttachMoneyIcon />
                        {item.offer?.projectRemuneration}
                        {/* <span className={classes.verticalLine}></span>
                        <span className={classes.projectStartTime}>
                          <ScheduleIcon fontSize="small" />
                          项目起止时间
                        </span> */}
                      </div>
                      <div className={classes.spread}>
                        <ScheduleIcon fontSize="small" />
                        {item.offer?.workload}
                      </div>
                    </div>
                    <p></p>
                    <div className={classes.itemBottom}>
                      <div className={classes.bottomRight}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={
                            // handleShowRowToDialog(rowData.row)
                            () => {
                              setOpen(true);
                            }
                          }
                        >
                          <VisibilityIcon fontSize="small" />
                          查看
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={
                            // handleShowRowToDialog(rowData.row)
                            () => {
                              setCheckBillsDialog(true);
                            }
                          }
                        >
                          <MenuBookIcon fontSize="small" />
                          查看账单
                        </Button>
                        {value === ConsultantProjectStatusEnum.IN_PROGRESS && (
                          <>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              className={classes.buttonMargin}
                              onClick={
                                // handleShowRowToDialog(rowData.row)
                                () => {
                                  setBreakDialog(true);
                                }
                              }
                            >
                              <StopIcon fontSize="small" />
                              终止项目
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              className={classes.buttonMargin}
                              onClick={() => {
                                // setOpen(true);
                              }}
                            >
                              <LocalAtmIcon fontSize="small" />
                              项目结算
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>
      </Grid>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          项目信息
        </DialogTitle>
        <DialogContent dividers>
          <PublishProjectView
            failShow={true}
            projectStatus={value}
            onClose={handleClose}
            editOpen={handleEditOpen}
            breakDialogOpen={breakDialogOpen}
            checkBillsHandleOpen={checkBillsHandleOpen}
          ></PublishProjectView>
        </DialogContent>
      </Dialog>

      <Dialog onClose={checkBillsHandleClose} open={checkBillsDialog} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={checkBillsHandleClose}>
          账单
        </DialogTitle>
        <DialogContent dividers>
          <CheckBillsView></CheckBillsView>
        </DialogContent>
      </Dialog>

      <CancelCompanyPublishProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
        }}
      ></CancelCompanyPublishProjectDialog>
      <ConsultantBreakProjectDialog
        isOpen={breakDialog}
        handleEdit={() => {
          setBreakDialog(false);
        }}
        handleDialogClose={() => {
          setBreakDialog(false);
        }}
      ></ConsultantBreakProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(projectListView);
