import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import { ProjectInfoListKnownAction } from '../reducers/projectProduceListReducer';
import debug from '../utils/debug';
import { query } from '../utils/graphql';
import { GET_COMPANY_ALL_PROJECT, GET_CONSULTANT_ALL_PROJECT } from '../utils/graphqlStrings';

export const consultantGetAllProject: Function = () => async (
  dispatch: Function
): Promise<ProjectInfo[]> => {
  debug('consultantGetAllProject');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(GET_CONSULTANT_ALL_PROJECT);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目信息失败' }));
    return [] as ProjectInfo[];
  }

  if (data && data?.consultantGetAllProject) {
    const consultantGetAllProject = data.consultantGetAllProject;
    debug('Get consultantGetAllProject successfully');
    debug(consultantGetAllProject);
    dispatch(ProjectInfoListKnownAction(consultantGetAllProject));
    dispatch(loadingEndAction());

    return consultantGetAllProject as ProjectInfo[];
  }
  return [] as ProjectInfo[];
};

export const companyGetAllProject: Function = () => async (
  dispatch: Function
): Promise<ProjectInfo[]> => {
  debug('companyGetAllProject');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(GET_COMPANY_ALL_PROJECT);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目信息失败' }));
    return [] as ProjectInfo[];
  }

  if (data && data?.companyGetAllProject) {
    const companyGetAllProject = data.companyGetAllProject;
    debug('Get companyGetAllProject successfully');
    debug(companyGetAllProject);
    dispatch(ProjectInfoListKnownAction(companyGetAllProject));
    dispatch(loadingEndAction());

    return companyGetAllProject as ProjectInfo[];
  }
  return [] as ProjectInfo[];
};
