import React from 'react';

import Layout from '../../layout/ConsultantLayout';
import MyProjectsListView from '../../views/consultant/myProjectsListView';
const MyProjects = (): React.ReactElement => {
  return (
    <Layout>
      <MyProjectsListView></MyProjectsListView>
    </Layout>
  );
};

export default MyProjects;
